import { User } from '../interfaces/user_interface'
import { ApiClient } from './api_client'

export class UserApi {
    /**
     * Récupère la liste des utilisateurs depuis le backend
     */
    // TODO : le type de retour de cette fonction est incorrect. Normalement, seules des informations allégées sur les utilisateurs sont retournées
    static async listUsers(): Promise<User[]> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.get('/users')
        } catch (e) {
            throw new Error(
                `Échec de récupération de la liste des utilisateurs`
            )
        }
    }

    /**
     * Récupérer les informations d'un utilisateur depuis le backend
     */
    static async getUser(id: number): Promise<User> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.get(`/users/${id}`)
        } catch (e) {
            throw new Error(
                `Echec de la récupération des données de l'utilisateur`
            )
        }
    }

    /**
     * Crée un utilisateur dans la base de données
     * @param user User
     * @param zones number[] < contient une liste d'id
     * @param devices number[] < contient la liste d'identifiants des devices associés à l'utilisateur
     */
    static async createUser(
        user: User,
        zones: number[], // TODO : supprimer ce paramètre
        devices: number[]
    ): Promise<any> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.post('/users/create', {
                body: JSON.stringify({
                    user: user,
                    zones: zones,
                    devices: devices,
                }),
            })
        } catch (e: any) {
            if(409 === e.code){
                throw new Error(`Création de l'utilisateur impossible :\nUtilisateur déjà existant.`)
            }
            throw new Error(`Echec de la création de l'utilisateur`)
        }
    }

    /**
     * Met à jour un utilisateur dans la base de données
     * @param user User
     * @param zones number[]
     * @param devices number[]
     */
    static async editUser(
        user: User,
        zones: number[],
        devices: number[] // TODO : vérifier si la suppression de devices a un effet
    ): Promise<any> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.put(`/users`, {
                body: JSON.stringify({
                    user: user,
                    zones: zones,
                    devices: devices,
                }),
            })
        } catch (e: any) {
            if(409 === e.code){
                throw new Error(`Modification de l'utilisateur impossible :\nAdresse mail déjà attibué à un autre utilisateur.`)
            }
            throw new Error(`Echec de l'édition de l'utilisateur`)
        }
    }

    /**
     * Supprime un compte utilisateur
     *
     * @param user_id L'identifiant du compte utilisateur à supprimer
     */
    static async deleteUser(user_id: number) {
        // TODO : supprimer ce try / catch
        try {
            await ApiClient.delete(`/users/${user_id}`)
        } catch (e) {
            throw new Error(
                `Echec de la suppression de l'utilisateur ${user_id}`
            )
        }
    }

    /**
     * Réinitialise le mot de passe de l'utilisateur par l'administrateur
     *
     * @param user_id number
     * @returns object {password: "string"}
     */
    // TODO : typer plus fortement le code de retour de cette fonction
    static async resetPassword(user_id: number): Promise<any> {
        try {
            return await ApiClient.get(
                `/users/${user_id}/set_temporary_password`
            )
        } catch (e) {
            throw new Error(`Echec de la réinitialisation du mot de passe`)
        }
    }

    /**
     * change l'état de l'user d'actif à inactif et inverse
     * @param user_id number
     */
    // TODO :  Supprimer complètement cette fonction et passer par la fonction de mise à jour du compte utilisateur
    static async toggleActive(user_id: number) {
        const user = await this.getUser(user_id)
        // TODO : voir pour construire le body plus correctement et éviter la redondance d'information au niveau des list de devices et zones...
        const body = {
            user: user,
            zones: user.zones,
            devices: user.devices,
        }

        body.user.isActive = !body.user.isActive
        try {
            await ApiClient.put(`/users`, {
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error(
                `Echec du changement d'état de l'utilisateur id : ${user_id}`
            )
        }
    }

    /**
     * Modifie en base la date de licence pour les utilisateurs correspondant aux IDs
     * @param users_id number[]
     * @param renewal_date string
     */
    // TODO : cette fonction est soit cassée, soit inutilisée. A corriger ou à supprimer
    static async groupedLicenceRenewal(
        users_id: number[],
        renewal_date: string // TODO : remplacer par un temps UNIX
        // TODO : remplacer renewal par new_expiration
    ): Promise<void> {
        const body = {
            users_id: users_id,
            renewal_date: renewal_date,
        }

        await ApiClient.post('/web/users/grouped/licence_renewal', {
            body: JSON.stringify(body),
        })
    }

    static async updateLicenceRenew(
        user : User):Promise<void>{
            const body = {
                        user: user,
                    }
                        await ApiClient.post('/users/renew_expiration_date', {
                            body: JSON.stringify(body),
                        })
        }

    /**
     * Permet à l'utilisateur connecté de réinitialiser son mot de passe
     */
    static async changePassword(password: string) {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.put(`/auth/reset_password`, {
                restricted: false,
                body: JSON.stringify({
                    password: password,
                }),
            })
        } catch (e) {
            throw new Error(`Echec de la mise à jour du mot de passe`)
        }
    }

    /**
     * Commence le protocol OpenId en récupérant l'url du portail d'authentification vers le quel l'utilisateur sera redirigé pour s'authentifier
     */
    // TODO : renommer startOpenIDLogin
    static async startOpenIdProtocol() {
        const data = await ApiClient.get('/auth/initiate_openid_login', {
            restricted: false,
        })
        window.location.href = data.loginUrl
    }

    /**
     * Connexion d'un utilisateur à l'aide d'un mot de passe
     * @param email
     * @param password
     * @returns
     * token : le token d'authentification indispensable à l'utilisation de l'application.
     * user : les informations de l'utilisateur connecté.
     */
    // TODO : typer fortement les valeurs de retour
    static async loginWithPassword(
        email: string,
        password: string
    ): Promise<any> {
        return await ApiClient.post('/auth/login_with_password', {
            body: JSON.stringify({
                email: email,
                password: password,
            }),
            restricted: false,
        })
    }

    /**
     * Récupère les informations de l'utilisateur courant
     */
    static async getInfoCurrentUser(): Promise<User> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.get('/auth/info', { restricted: false })
        } catch (e) {
            throw new Error(
                `Echec de la récupération des informations de l'utilisateur connecté`
            )
        }
    }

    // TODO : renommer cette fonction (getMailContact)
    static async forgottenPassword(): Promise<any> {
        return await ApiClient.get('/config/mail_contact', {
            restricted: false,
        })
    }
}
